<script module lang="ts">
	type TProps = {
		onclick: () => void;
	};
</script>

<script lang="ts">
	import Padding from '$lib/components/Padding.svelte';
	import Button from '$lib/components/buttons/Button.svelte';
	import { Icon } from '$lib/components/icon';
	import EntypoCross from '~icons/entypo/cross';

	const { onclick }: TProps = $props();
</script>

<div class="CrossCircle">
	<Button backgroundColour="secondary-red-contrast" {onclick} borderRadius={8}>
		<Padding all={0.5}>
			<Icon size={4} icon={EntypoCross} colour="secondary-white" />
		</Padding>
	</Button>
</div>

<style lang="scss">
	$transformsX: calc(50% - var(--half-padding));
	$transformsY: calc(-50% + var(--half-padding));

	.CrossCircle {
		position: absolute;
		right: 0;
		top: 0;

		transform: translate($transformsX, $transformsY);

		display: inline-flex;
	}
</style>
